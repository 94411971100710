import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';

/* render main component  */

ReactDOM.render(
	<Main />,
	document.getElementById('root')
	);
