import React from 'react';
import { BrowserRouter as HashRouter, Route, Link, Switch } from "react-router-dom";
import ToDoContainer from "../widgets/todoapp/ToDoContainer";
import UserContextApp from "../widgets/usecontext/UserContextApp";
import ApiApp from "../widgets/apicall/ApiApp";
import RateShopper from "../widgets/rateshopper/RateShopper";

const URL = "http://garytohill.com"

function Widget() {
    return (
       
            <div className="container pt-5">
                <h3 className="text-center">React Widgets</h3>
                <HashRouter>
                    <div className="pt-5">
                        <h4>To Do App</h4>
                        <div>
                            <p>
                                React app that demonstrates basic CRUD functions in a To Do format
                    </p>
                    <a href={URL+"/todoapp"} >See To Do App Widget</a>
                            

                        </div>
                    </div>
                    <div className="pt-5">
                        <h4>API Call</h4>
                        <div>
                            <p>
                                This App makes a GET request using axios async to fetch jokes from https://official-joke-api.appspot.com
                    </p>
                    <a href={URL+"/apiapp"} >See GET Some Jokes Widget</a>
                        </div>
                    </div>
                    <div className="pt-5">
                        <h4>Use Contect App</h4>
                        <div>
                            <p>
                                App that implements React Hooks useContext api to pass props to a child component deep within the component tree
                    </p>
                    <a href={URL+"/usecontext"} >Pass State to Ancestors</a>
                        </div>
                    </div>
                    <div className="py-5">
                        <h4>Rate Shopper </h4>
                        <div>
                            <p>
                                Rate Shopper app takes a input a dollar amount to invest and the term, then selects the best rate of return from the table of offers.
                    </p>
                    <a href={URL+"/rateshopper"} > Get The Best Rate</a>
                        </div>
                    </div>
                    <Switch>
                        <div className="bg-light">                            
                            <Route exact path="/todoapp" component={ToDoContainer} />
                            <Route path="/usecontext" component={UserContextApp} />
                            <Route path="/apiapp" component={ApiApp} />
                            <Route path="/rateshopper" component={RateShopper} />
                        </div>
                    </Switch>
                </HashRouter>
            </div>
       
    )
}

export default Widget;